import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import Accordion from "../components/accordion/accordion"
import { articles } from "../utils/newsInformation"


const News = () => {


  return (
    <Layout SEOTitle={"News | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

      <Accordion props={articles} />

    </Layout>
  )
}

export default News