export const articles = {
    title: 'Take a look at our recent posts',
    contents: [
        {
            Question: 'What is motor legal protection',
            Answer: 'Take a look at our quick informative video which explains what motor legal protection does and how you can get the benefits of motor legal protection free for life by becoming a member of Free Motor Legal.',
            URL: 'what-is-motor-legal-protection-2'
        },
        {
            Question: 'Are you ready for those summer holiday car journeys? Our top 10 tips',
            Answer: 'Avoid stress whilst you’re on the road and plan ahead. It is well worth planning a route to take across the country, planning roughly how much you can spend each day and planning activities that you can stop and do along the way.',
            URL: 'are-you-ready-for-those-summer-holiday-car-journeys-our-top-10-tips'
        },
        {
            Question: 'Motor legal protection – the conversation nobody is yet having ahead of the Civil Liability Bill',
            Answer: 'The government shows no signs in halting the progression of the Civil Liability Bill, with very few amendments taking place as it progresses through the parliamentary channels. Plans remain to have the blocks in place for April 2019.',
            URL: 'motor-legal-protection-the-conversation-nobody-is-yet-having-ahead-of-the-civil-liability-bill'
        },
        {
            Question: 'Top 10 tips to save on your motor insurance costs',
            Answer: 'Get the right level of cover – Fully comp or Third Party? - It used to be that third party fire and theft cover was always cheaper, but in recent years insurers have been happy to provide lower premiums for fully comprehensive as customers with fully comp cover seem to be a better risk and less likely to claim. So get more for less by getting quotes for fully comprehensive cover as well as TPFT.',
            URL: 'top-10-tips-to-save-on-your-motor-insurance-costs'
        },
        {
            Question: 'Insurance companies continue to play everyone for fools',
            Answer: 'Another day and more reports from insurers of increased profits and reduced combined operating ratios, yet we are all paying more for our motor insurance than ever.',
            URL: 'insurance-companies-continue-to-play-everyone-for-fools'
        },
        {
            Question: '5 things you now get for free that you always used to pay for',
            Answer: 'Although it seems like prices on goods and services only ever go up (have you checked what is happening with car insurance? – (the greedy sods) if you look around, there are some things you can now get for free, largely due to what we call “disruptors” in the market place.',
            URL: '5-things-you-now-get-for-free-that-you-always-used-to-pay-for'
        },
        {
            Question: 'Do you know your speed cameras?',
            Answer: 'Can you tell the difference and know how they work? - OK I have to profess a bit of personal interest in raising this post and bringing this subject to the attention of our members as I recently got nabbed for speeding by a mobile camera van. A fair cop, I was exceeding the speed limit and got caught. Although I would argue the van had been placed in an advantageous spot where it was not in view until it was too late and I was already in the sights of the salivating camera operator.',
            URL: 'do-you-know-your-speed-cameras'
        },
        {
            Question: 'Are whiplash sufferers really the cause of higher insurance premiums?',
            Answer: 'The Whiplash reforms proposed by the government on 17/11/16 are sponsored by the insurance industry and will struggle to pass on the alleged £40 savings per year on motor insurance costs.',
            URL: 'are-whiplash-sufferers-really-the-cause-of-higher-insurance-premiums'
        },
        {
            Question: 'More reasons why your insurance premiums are going up',
            Answer: '1 - Insurance Premium Tax (IPT) increased in November 2015 – Previously this tax was set at 6%, but George Osborne hiked it to 9.5% with effect from November 2015 – this saw premiums increase. But matters are not finished! A further hike to 10% is to coming into effect on 1st October 2016. So premiums can be expected to rise slightly more due to the actions of the treasury. The hike in IPT, which affects not only motor insurance, but also household insurance, pet insurance and legal expenses insurance, is expected to net the Treasury and additional £8.1 Billion by 2021.',
            URL: 'the-real-reason-your-insurance-premiums-are-going-up'
        },
        {
            Question: 'The REAL Reasons your motor insurance has been going up',
            Answer: '1 - Solvency ii – a requirement from January 2016 for insurers to hold more capital reserves (money in the bank to meet claims and liabilities). So they can’t invest as much in stocks & shares or buy shopping malls etc. Believe it or not, motor insurers typically don’t make their money from simple underwriting profits (collecting more in premiums than they pay out in claims) although many have moved into this territory in recent years with Combined Operating Ratios (COR’s) as low as 85% (meaning for every £1 they collect in premiums, 85p is paid out in claims). Because they don’t have as much spare cash they can pump into investments, this has caused them to look elsewhere for increased profits – yep you guessed it – their customers; Crank up the premiums, crank up the profits!',
            URL: 'the-real-reasons-your-motor-insurance-has-been-going-up'
        }
    ]
}